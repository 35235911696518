<template>
    <div>
        <div class="d-flex" v-if="checkedOctane || checkedRedshift || checkedC4d">
            <div v-if="checkedOctane" class="ml-2">
                <el-tooltip class="item" effect="dark" content="Octane renderer" placement="top">
                    <img src="/icon/OctaneRender.svg" style="width: 0.8rem;" />
                </el-tooltip>
            </div>
            <div v-if="checkedRedshift" class="ml-2">
                <el-tooltip class="item" effect="dark" content="Redshift renderer" placement="top" >
                    <img src="/icon/RedshiftRender.svg" style="width: 0.8rem;" />
                </el-tooltip>
            </div>
            <div v-if="checkedC4d" class="ml-2">
                <el-tooltip class="item" effect="dark" content="Cinema 4D" placement="top">
                    <img src="/icon/cinema4d.png" style="width: 0.8rem;" />
                </el-tooltip>
            </div>
            <div v-if="checkedBlender" class="ml-2">
                <el-tooltip class="item" effect="dark" content="Blender" placement="top">
                    <img src="/icon/blender.png" style="width: 1rem;" />
                </el-tooltip>
            </div>
        </div>
        <!--<div v-for="(softwareItem, swIndex) in renderSoftwareFocusArray.RenderSoftware"
             :key="swIndex">
            {{softwareItem}}
        </div>-->
    </div>
</template>
d�
<script>
    import baseComponent from "@/scripts/baseComponent";
    import userApi from '@/api/common/users';
    export default {
        extends: baseComponent,
        props: {
            userId: {
                type: String,
                default: null,
            }
        },
        data() {
            return {
                renderSoftwareFocusArray: [],
                checkedOctane: false,
                checkedRedshift: false,
                checkedC4d: false,
                checkedBlender: false,
            };
        },
        mounted() {
            this.getRenderSoftwareFocusByUser();
        },
        methods: {
            getRenderSoftwareFocusByUser() {
                if (this.userId && this.userId !== null) {
                    userApi.getRenderSoftwareFocusByUser(this.userId)
                        .then(responese => {
                            this.renderSoftwareFocusArray = responese.data.data;
                            try {
                                if (this.renderSoftwareFocusArray && this.renderSoftwareFocusArray.RenderSoftware) {
                                    this.renderSoftwareFocusArray.RenderSoftware = JSON.parse(this.renderSoftwareFocusArray.RenderSoftware);
                                    this.renderSoftwareFocusArray.RenderSoftware.forEach(x => {
                                        if (x.toLowerCase().indexOf('octane') > -1) this.checkedOctane = true;
                                        if (x.toLowerCase().indexOf('redshift') > -1) this.checkedRedshift = true;
                                        if (x.toLowerCase().indexOf('blender') > -1) this.checkedBlender = true;
                                        if (x.toLowerCase().indexOf('cinema4d') > -1 || x.toLowerCase().indexOf('cinema 4d') > -1) this.checkedC4d = true;
                                    });
                                    this.$emit('input', {
                                        isHighlight: this.checkedOctane || this.checkedRedshift || this.checkedC4d
                                    });
                                }
                            } catch (error) { 
                                console.error(error);
                            }
                        })
                        .catch(error => { 
                            console.error(error);
                        });
                }
            }
        }
    }
</script>